var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":30,"width":3}})],1):(_vm.bank)?_c('v-sheet',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.bank.name)+" "),_c('span',{staticClass:"grey--text font-weight-regular"},[_vm._v(" ("+_vm._s(_vm.bank.code)+") ")])])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.hasEditAccess,"link":""},on:{"click":_vm.editBank}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$lang("btn.edit"))+" ")])],1),_c('v-list-item',{class:{
              'red--text': !_vm.isDeleteDisabled,
            },attrs:{"link":"","disabled":_vm.isDeleteDisabled},on:{"click":function($event){_vm.isDeleteDialogVisible = true}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$lang("btn.delete"))+" ")])],1)],1)],1),_c('v-btn',{staticClass:"ml-1",attrs:{"to":{
          name: _vm.AppSection.BANKS,
        },"icon":"","exact":""}},[_c('v-icon',[_vm._v("close")])],1)],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-3",attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.address.title"))+": ")]),_vm._v(" "+_vm._s(Object.values(_vm.bank.address).join(", "))+" ")]),_c('v-col',{staticClass:"mr-3",attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.banks.swift"))+": ")]),_vm._v(" "+_vm._s(_vm.bank.swift)+" ")]),(_vm.ibans)?_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.banks.ibans"))+": ")]),_vm._v(" "+_vm._s(_vm.ibans)+" ")]):_vm._e()],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold",class:_vm.dark ? 'white--text' : 'black--text'},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.title"))+" ")])]),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.COUNTERPARTIES_HEADERS,"items":_vm.counterparties,"loading":_vm.isCounterpartiesLoading,"sort-by":"name"},on:{"click:row":_vm.routeToCounterpartyPage}})],1)],1),_c('bank-form',{attrs:{"editing-item":_vm.editingItem},model:{value:(_vm.isSideBarVisible),callback:function ($$v) {_vm.isSideBarVisible=$$v},expression:"isSideBarVisible"}}),_c('confirmation-dialog',{attrs:{"loading":_vm.isBankDeleting,"title":_vm.$lang('accountingPortal.banks.deleteDialog.title'),"text":_vm.$lang('accountingPortal.banks.deleteDialog.body'),"btn-text":_vm.$lang('btn.delete')},on:{"confirm":_vm.deleteBank},model:{value:(_vm.isDeleteDialogVisible),callback:function ($$v) {_vm.isDeleteDialogVisible=$$v},expression:"isDeleteDialogVisible"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }