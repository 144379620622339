










































































































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import BankForm from "@/accounting-portal/components/banks/BankForm.vue";
import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import BankResponseModel from "@/accounting-portal/models/banks/BankResponseModel";
import CounterpartyShortResponseModel from "@/accounting-portal/models/counterparties/CounterpartyShortResponseModel";
import { AppSection } from "@/shared/models";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: {
    BankForm,
    ConfirmationDialog,
  },
})
export default class BankView extends mixins(AppSectionAccessMixin) {
  readonly AppSection = AppSection;
  readonly COUNTERPARTIES_HEADERS = [
    {
      text: this.$lang("accountingPortal.counterparties.name"),
      value: "name",
    },
  ];
  isSideBarVisible = false;
  isDeleteDialogVisible = false;
  editingItem: BankResponseModel | null = null;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get bankId(): string {
    return this.$route.params.bankId;
  }

  get bank(): BankResponseModel {
    return this.$store.state.bankStore.bank;
  }

  get loading(): boolean {
    return this.$store.state.bankStore.isBankLoading;
  }

  get appSection(): AppSection {
    return AppSection.BANKS;
  }

  get isBankDeleting(): boolean {
    return this.$store.state.bankStore.isBankDeleting;
  }

  get counterparties(): Array<CounterpartyShortResponseModel> {
    return this.$store.state.counterpartyStore.shortCounterparties;
  }

  get isCounterpartiesLoading(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartiesLoading;
  }

  get isDeleteDisabled(): boolean {
    return !this.hasDeleteAccess || !!this.counterparties.length;
  }

  get hasCounterpartiesAccess(): boolean {
    return !!this.currentUser.viewAccessEntities[this.applicationId]?.includes(
      AppSection.COUNTERPARTIES
    );
  }

  get ibans(): string {
    return this.bank.ibans
      .map((item) => `${item.iban} (${item.currency})`)
      .join("; ");
  }

  @Watch("bankId", { immediate: true })
  watchBankId(value: string) {
    this.$store.dispatch("loadBank", value);

    if (this.hasCounterpartiesAccess) {
      this.$store.dispatch("loadCounterpartiesByBankId", value);
    }
  }

  destroyed() {
    this.$store.commit("clearBank");
  }

  routeToBanksList() {
    this.$router.push({ name: AppSection.BANKS });
  }

  editBank() {
    if (!this.hasEditAccess) {
      return;
    }

    this.editingItem = BankResponseModel.of(this.bank);
    this.isSideBarVisible = true;
  }

  async deleteBank() {
    await this.$store.dispatch("deleteBank", Number(this.bankId));
    this.isDeleteDialogVisible = false;
    this.routeToBanksList();
  }

  routeToCounterpartyPage(item: CounterpartyShortResponseModel) {
    const route = this.$router.resolve({
      name: "counterparty",
      params: {
        counterpartyId: String(item.id),
      },
    });

    window.open(route.href, "_blank");
  }
}
